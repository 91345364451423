<script>
import ReturnStatusesForm from './ReturnStatusesForm'

export default {
  extends: ReturnStatusesForm,

  methods: {
    onSubmit() {
      this.createReturnStatus(this.form)
        .then(() => {
          this.successSubmit({ message: 'Статус успешно создан', routeName: 'return-statuses' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>